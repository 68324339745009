import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"
import WorkUpBanner from "@components/workUpBanner";
import ContactSection from '@components/contactSection';
import ProductFeatures from '@components/productFeatures';
import PricingSection from '@components/pricingSection';
import { SignupUrl } from '@constants/app';

import '@styles/styles.scss';

export const WorkUpPage = ({ data }) => {
  const ctf = data.contentfulWorkUpPage;
  const { headline, subtitle } = ctf;
  const assets = data.allContentfulAsset.edges.map(edge => edge.node);

  const featuresTitle = ctf.valuePropositionTitle;
  const featuresSubtitle = ctf.valuePropositionSubtitle.valuePropositionSubtitle;
  const features = ctf.features;

  const pricingSectionTitle = ctf.pricingSectionTitle;
  const pricingSectionSubtitle = ctf.pricingSectionSubtitle.pricingSectionSubtitle;
  const pricingSectionFeatures = ctf.pricingSectionFeatures;
  const pricingSectionPricings = ctf.pricings;

  return (
    <Layout>
      <SEO title="Work Up" />
      <WorkUpBanner
        headline={headline}
        subtitle={subtitle.subtitle}
        assets={assets}
      />
      <ProductFeatures
        title={featuresTitle}
        subtitle={featuresSubtitle}
        features={features}
        assets={assets}
      />
      <PricingSection
        title={pricingSectionTitle}
        subtitle={pricingSectionSubtitle}
        features={pricingSectionFeatures}
        border={true}
      >
        <div className="row">
          {pricingSectionPricings.map(({ title, price, pricePer, description }) => (
            <div className="col-md-6">
              <div className="card mt-4 mt-md-0">
                <div className="card-header">
                  <span className="font-weight-bold">{title}</span>
                </div>
                <div className="card-body d-flex flex-column py-5">
                  <div 
                    className="flex-center flex-column mb-5"
                    style={{ height: '50px' }}
                  >
                    <h3 className="h1 text-blue mb-0">
                      ${price}
                    </h3>
                    <small className="text-muted">
                      {pricePer}
                    </small>
                  </div>
                  <p className="lead text-muted flex-center flex-grow">
                    {description}
                  </p>
                </div>
                <div className="card-footer bg-white border-top-0">
                  <div className="flex-center pb-3">
                    <a
                      href={SignupUrl}
                      className="btn btn-sm btn-blue"
                    >Sign Up Now</a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </PricingSection>
      <ContactSection
        defaultProduct={'Work Up'}
      />
    </Layout>
  );
};

export default WorkUpPage;

export const pageQuery = graphql`
  query WorkUpQuery {
    contentfulWorkUpPage {
      headline
      subtitle {
        subtitle
      }
      features {
        featureContent
        featureTitle
        imageTitle
      }
      valuePropositionTitle
      valuePropositionSubtitle {
        valuePropositionSubtitle
      }
      pricingSectionTitle
      pricingSectionSubtitle {
        pricingSectionSubtitle
      }
      pricingSectionFeatures {
        text
      }
      pricings {
        title
        price
        pricePer
        description
      }
    }
    allContentfulAsset {
      edges {
        node {
          file {
            contentType
            url
            fileName
          }
          title
        }
      }
    }
  }
`;
